// import amplifyConfig from './amplifyconfiguration.json';

export const SETTINGS = {
  // DynamoDB
  // NOTE: Currently not used as not using amplify sdk to connect to api
  // DYNAMODB_API_NAME: amplifyConfig.aws_cloud_logic_custom[0].name,
  // Admin
  ADMIN_STRAVA_ID: '19454260',
  // Strava
  STRAVA_CLIENT_SECRET: import.meta.env.VITE_STRAVA_CLIENT_SECRET,
  STRAVA_CLIENT_ID: import.meta.env.VITE_STRAVA_CLIENT_ID,
  STRAVA_REDIRECT_URI: `${window.location.protocol}//${window.location.host}`,
  STRAVA_SCOPE: 'read_all,profile:read_all,activity:read_all',
  get STRAVA_TOKEN_ENDPOINT() {
    return `https://www.strava.com/oauth/token?client_id=${this.STRAVA_CLIENT_ID}&client_secret=${this.STRAVA_CLIENT_SECRET}`;
  },
  get STRAVA_AUTH_ENDPOINT() {
    return `https://www.strava.com/oauth/authorize?client_id=${this.STRAVA_CLIENT_ID}&redirect_uri=${this.STRAVA_REDIRECT_URI}&scope=${this.STRAVA_SCOPE}&response_type=code`;
  },
  STRAVA_DEAUTH_ENDPOINT: 'https://www.strava.com/oauth/deauthorize',
  // Mapbox
  MAPBOX_API_KEY: import.meta.env.VITE_MAPBOX_API_KEY,
  MAPBOX_THEME_DARK: 'cjkidvmd53o0w2spilirxuk7i',
  MAPBOX_THEME_LIGHT: 'cl92tv0g5003j14pd2j8suun3',
  // Stripe
  STRIPE_KEY_TEST: import.meta.env.VITE_STRIPE_KEY_TEST,
  STRIPE_KEY_LIVE: import.meta.env.VITE_STRIPE_KEY_LIVE,
  // API
  API_CACHE_NAME: 'headwind-cache',
  API_CACHE_EXPIRES_AFTER_HOURS: 168, // 168 hours = 1 week
  // Hardness
  HARDNESS_MAX_WIND_SPEED: 30,
  HARDNESS_OUT_OF: 5,
  HARDNESS_GETS_HARD_AT: 3, // used to turn the hardness out of 5 text red
  HARDNESS_WIND_GETS_HARD_AT: 30, // used to calc wind color
  HARDNESS_WIND_GETS_EASY_AT: 10, // used to calc wind color
  // Default user settings
  DEFAULT_SETTINGS_DISTANCE: 'kilometers',
  DEFAULT_SETTINGS_RAINFALL: 'millimeters',
  DEFAULT_SETTINGS_TEMPERATURE: 'celsius',
  DEFAULT_SETTINGS_SPEED: 'KPH',
  DEFAULT_SETTINGS_MAX_AVERAGE_SPEED: 60,
  DEFAULT_SETTINGS_DEFAULT_AVERAGE_SPEED: 20,
  DEFAULT_SETTINGS_COMMUTE_MORNING: '07:00',
  DEFAULT_SETTINGS_COMMUTE_AFTERNOON: '17:00',
  // Datepicker settings
  DATEPICKER_MAX_FORECAST_DAYS: 14,
  DATEPICKER_MIN_FORECAST_DAYS: 7,
  // Calorie calculations
  CALORIE_CALCULATION_AVERAGE_FEMALE_WEIGHT: 70,
  CALORIE_CALCULATION_AVERAGE_MALE_WEIGHT: 85,
  CALORIE_CALCULATION_AVERAGE_BIKE_WEIGHT: 9,
  CALORIE_CALCULATION_REALITY_ADJUSTMENT: 19,
  // Geolocation
  IP_GEOLOCATION: `https://api.ipgeolocation.io/ipgeo?apiKey=${import.meta.env.VITE_GEOLOCATION_API_KEY}`,
  // Donation amount
  DONATION_AMOUNT_CENTS: 500,
  // Demo details
  DEMO_STRAVA_TOKEN: 'DEMO',
  // Map
  MAP_WINDMARKER_PADDING: 20,
  // Sentry
  SENTRY_DSN: 'https://785da116ccd242b8a5cd60108fecba4f@sentry.io/1260534',
  // Google analytics
  GOOGLE_ANALYTICS_LIVE: 'G-FMNRN4WJ9N',
  GOOGLE_ANALYTICS_DEVELOP: 'G-PLGFL3E56W',
  GOOGLE_ANALYTICS_LOCAL: 'G-JTYYK6XKD0',
} as const;

export type Settings = typeof SETTINGS;
