import { Component, For, Show, createSignal, onMount } from 'solid-js';
import styles from './SegmentSelector.module.scss';
import { themeClass } from '../ThemeProvider';
import cloneDeep from 'lodash.clonedeep';
import cn from 'classnames';
import { Icon } from '../Icon';
import { ScrollContainer } from '../ScrollContainer';
import { RideOrRouteSegment, RideOrRouteSegments } from '../../types';
import { isRideSegment } from '../../types/guards';
import { DonateButton } from '../DonateButton';
import { SETTINGS } from '../../settings';
import { isSharedOrEmbeddedRide } from '../../utils';
import { StravaButton } from '../StravaButton';
import { initAuth } from '../../api';
import { Link } from '@solidjs/router';

interface Props {
  segments: RideOrRouteSegments;
  onSegmentSelect: (segment: RideOrRouteSegment | null) => void;
  subscribed?: boolean;
  loggedIn?: boolean;
}

export const SegmentSelector: Component<Props> = props => {
  const [segments, setSegments] = createSignal<RideOrRouteSegments>([]);
  const [active, setActive] = createSignal(-1);

  onMount(() => {
    const sortedSegments = cloneDeep(props.segments).sort((a, b) => {
      if (isRideSegment(a) && isRideSegment(b)) {
        return Number(b.starred) - Number(a.starred);
      }
      return 0;
    });
    setSegments(sortedSegments);
  });

  const onSelectSegment = (segment: RideOrRouteSegment | null) => {
    if (props.subscribed) {
      setActive(segment?.id || -1);
      props.onSegmentSelect(segment);
    }
  };

  const getIcon = (segment: RideOrRouteSegment) => {
    if (isRideSegment(segment) && segment.starred) {
      return 'IconStarFilled';
    }
    return 'IconRoute';
  };

  return (
    <ScrollContainer class={themeClass(styles.dark, styles.container)} disabled={!props.subscribed}>
      <Show when={!props.subscribed}>
        <div class={styles.paymentBarrier}>
          <div class={styles.paymentBarrierInner}>
            <Show
              when={props.loggedIn && !isSharedOrEmbeddedRide()}
              fallback={
                <>
                  <p>Connect with Strava to access the segment explorer.</p> <StravaButton full onClick={initAuth} />
                </>
              }
            >
              <p>
                Donate to gain access to segment explorer. Your donation is much appreciated and will help keep Headwind
                free for everyone. &nbsp;
                <Link href="/demo">View the demo.</Link>
              </p>
              <DonateButton amount={SETTINGS.DONATION_AMOUNT_CENTS} showSpiel={false}>
                Donate
              </DonateButton>
            </Show>
          </div>
        </div>
      </Show>

      <ul class={styles.list}>
        <li class={cn(styles.item, { [styles.active]: active() === -1 })} onClick={onSelectSegment.bind(null, null)}>
          <Icon type="IconBike" size="small" /> Whole Ride
        </li>
        <For each={segments()}>
          {segment => (
            <li
              class={cn(styles.item, { [styles.active]: active() === segment.id })}
              onClick={onSelectSegment.bind(null, segment)}
            >
              <Icon type={getIcon(segment)} size="small" />
              {segment.name}
            </li>
          )}
        </For>
      </ul>
    </ScrollContainer>
  );
};
