import { Color } from 'three';
import { fragmentShader } from './fragment';
import { vertexShader } from './vertex';

export const ChromaKeyShader = {
  name: 'ChromaKeyShader',
  uniforms: {
    tDiffuse: {
      value: null,
    },
    keyColor: {
      value: new Color(0x00ff00),
    },
    texWidth: {
      value: 1024,
    },
    texHeight: {
      value: 1024,
    },
    similarity: {
      value: 0.4,
    },
    smoothness: {
      value: 0.08,
    },
    spill: {
      value: 0.1,
    },
  },
  vertexShader: vertexShader,
  fragmentShader: fragmentShader,
};
