import { useNavigate } from '@solidjs/router';
import { addDays, getUnixTime } from 'date-fns';
import { showToast } from '../components';
import { SETTINGS } from '../settings';
import { useStore } from '../store';
import {
  DonateApiResponse,
  DynamoTableInfoResponse,
  HeadwindUserSettings,
  RideApiResponse,
  RidesApiResponse,
  RouteApiResponse,
  RoutesApiResponse,
  SegmentApiResponse,
  StarredRideIdsApiResponse,
  StravaAccessToken,
  UserApiResponse,
} from '../types';
import { storage } from '../utils';
import { ApiCallback, apiRequest, request } from './api';

export const initDemo = () => {
  const { updateStore } = useStore();
  const navigate = useNavigate();
  updateStore.stravaToken(null);
  storage.clear();
  storage.remember(false);
  updateStore.stravaToken({
    access_token: SETTINGS.DEMO_STRAVA_TOKEN,
    expires_at: addDays(new Date(), 100).valueOf(),
    refresh_token: SETTINGS.DEMO_STRAVA_TOKEN,
  });
  navigate('/');
  getUser(user => {
    // Update the user data
    if (user.data && user.success) {
      updateStore.stravaUserID(String(user.data.id));
      updateStore.stravaUserName(user.data.username);
      updateStore.stravaUserAvatar(user.data.profile_medium);
      if (user.data.headwind) {
        updateStore.settings(user.data.headwind);
      }
      showToast('Successfully logged in to demo', 'success');
    }
  });
};

export const initAuth = () => {
  const { updateStore } = useStore();
  updateStore.stravaToken(null);
  window.location.href = SETTINGS.STRAVA_AUTH_ENDPOINT;
};

export const logout = async () => {
  const navigate = useNavigate();
  const { resetStore } = useStore();
  await storage.clear();
  await resetStore();
  navigate('/');
  setTimeout(() => {
    showToast('Successfully logged out', 'success');
  }, 1000);
};

export const checkForAuthToken = async () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const scope = params.get('scope');
  const error = params.get('error');
  if (code || scope) {
    window.history.pushState({}, document.title, window.location.pathname);
  }
  if (error && error === 'access_denied') {
    showToast('Sorry, there was an error authorizing Strava.', 'error');
    return false;
  } else if (error) {
    showToast(`Sorry an error occurred. '${error}'`, 'error');
    return false;
  } else if (code && scope) {
    storage.clear();
    const requestedScope = SETTINGS.STRAVA_SCOPE.split(',');
    const grantedScope = scope.split(',').filter(s => s !== 'read');
    const match = requestedScope.filter(s => grantedScope.includes(s));
    if (match.length === requestedScope.length) {
      await requestAccessToken(code);
      return true;
    } else {
      const errorMsg = `
        Please allow all requested permissions to use this app.
        By default Strava sets all your rides and routes to private. We require
        these permissions so we can fetch your ride and route details to calculate
        the hardness of your rides and show you in depth heatmaps.
        Don't worry we will never share these details with anyone else.
      `;
      setTimeout(() => {
        showToast(errorMsg, 'error', 0, 15000);
      }, 1000);
      return false;
    }
  }
};

const accessTokenIsValid = (token: StravaAccessToken) => {
  const validTime = token.expires_at - getUnixTime(new Date());
  if (validTime <= 0) {
    return false;
  }
  return true;
};

const requestAccessToken = (code: string) => {
  return new Promise(resolve => {
    const endpoint = `${SETTINGS.STRAVA_TOKEN_ENDPOINT}&code=${code}`;
    const { updateStore } = useStore();
    updateStore.requestingStravaToken(true);
    request(
      endpoint,
      resp => {
        const { data, success } = resp;
        if (success && data.access_token && data.refresh_token && data.athlete) {
          const token = {
            access_token: data.access_token,
            refresh_token: data.refresh_token,
            expires_at: data.expires_at,
          };
          // NOTE: Cant use store.updateAll here as we need the functionality
          // to be triggered where the data is put into local storage
          updateStore.stravaToken(token);
          updateStore.stravaUserID(data.athlete.id);
          updateStore.stravaUserName(data.athlete.username);
          updateStore.stravaUserAvatar(data.athlete.profile_medium);
          updateStore.requestingStravaToken(false);
          resolve(true);
        } else {
          // TODO: consolidate errors. Only really want to show 1. Maybe an error ID to make unique.
          const error = 'Error retrieving access token from Strava.';
          showToast(error, 'error');
          updateStore.requestingStravaToken(false);
          resolve(true);
        }
      },
      true,
    );
  });
};

const refreshAccessToken = async (token: StravaAccessToken) => {
  const endpoint = `${SETTINGS.STRAVA_TOKEN_ENDPOINT}&grant_type=refresh_token&refresh_token=${token.refresh_token}`;
  const { updateStore } = useStore();
  let newToken: StravaAccessToken | null = null;
  await request(
    endpoint,
    resp => {
      const { data, success } = resp;
      if (success && data.access_token && data.refresh_token) {
        newToken = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expires_at: data.expires_at,
        };
        updateStore.stravaToken(newToken);
      } else {
        // TODO: consolidate errors. Only really want to show 1. Maybe an error ID to make unique.
        const error = 'Error refreshing access token from Strava.';
        showToast(error, 'error');
      }
    },
    true,
  );
  return newToken;
};

export const queryStravaApi = async (endpoint: string, callback: ApiCallback, skipCache = false) => {
  const { store } = useStore();
  let token = store().stravaToken;
  if (token?.access_token === SETTINGS.DEMO_STRAVA_TOKEN) {
    if (endpoint.includes('star/ride/')) {
      showToast('Starring rides is not permitted in demo mode', 'warning');
    } else if (endpoint.includes('strava/update')) {
      showToast('Updating settings is not permitted in demo mode', 'warning');
    } else {
      const demoEndpoint = endpoint.replace('strava/get/', 'demo-api/') + '.json';
      request(demoEndpoint, callback, false);
    }
    return true;
  } else {
    if (!token) {
      initAuth();
      return false;
    }
    const tokenValid = accessTokenIsValid(token);
    if (!tokenValid) {
      token = await refreshAccessToken(token);
    }
    if (!token) {
      initAuth();
      return false;
    }
    apiRequest(`${endpoint}/${token.access_token}`, callback, skipCache);
    return true;
  }
};

export const getUser = (callback: (user: UserApiResponse) => void) => {
  return queryStravaApi('/strava/get/user', callback, true);
};

export const getRides = (callback: (rides: RidesApiResponse) => void, skipCache = false) => {
  return queryStravaApi(`/strava/get/rides`, callback, skipCache);
};

export const getRoutes = (callback: (routes: RoutesApiResponse) => void, skipCache = false) => {
  const { store } = useStore();
  const uid = store().stravaUserID;
  if (!uid) {
    showToast('Error fetching routes. No strava user id found.', 'error');
  } else {
    return queryStravaApi(`/strava/get/routes/${uid}`, callback, skipCache);
  }
};

export const getStarredRides = (callback: (rides: RidesApiResponse) => void, skipCache = false) => {
  const { store } = useStore();
  const uid = store().stravaUserID;
  if (!uid) {
    showToast('Error fetching starred rides. No strava user id found.', 'error');
  } else {
    return queryStravaApi(`/strava/get/starred-rides/${uid}`, callback, skipCache);
  }
};

// TODO: may as well get the server to send back the starred rides after
// starring or un-staring rides so we don't need to make another call to
// get starred rides api endpoint
// TODO: Need to validate on the API end that the rideID passed here actually exists
// before adding it to the starred rides id array table
export const starRide = (rid: number, callback: () => void) => {
  const { store } = useStore();
  const uid = store().stravaUserID;
  if (!uid) {
    showToast('Error starring ride. No strava user id found.', 'error');
  } else {
    return queryStravaApi(`/strava/star/ride/${rid}/${uid}`, callback, true);
  }
};

export const unStarRide = (rid: number, callback: () => void) => {
  const { store } = useStore();
  const uid = store().stravaUserID;
  if (!uid) {
    showToast('Error un-staring ride. No strava user id found.', 'error');
  } else {
    return queryStravaApi(`/strava/unstar/ride/${rid}/${uid}`, callback, true);
  }
};

export const getStarredRideIds = (callback: (starredRideIds: StarredRideIdsApiResponse) => void, skipCache = false) => {
  const { store } = useStore();
  const uid = store().stravaUserID;
  if (!uid) {
    showToast('Error fetching starred ride ids. No strava user id found.', 'error');
  } else {
    return queryStravaApi(`/strava/get/starred-ride-ids/${uid}`, callback, skipCache);
  }
};

export const getRide = (id: string, callback: (ride: RideApiResponse) => void, skipCache = false) => {
  return queryStravaApi(`/strava/get/ride/${id}`, callback, skipCache);
};

export const getSegment = (id: string, callback: (segment: SegmentApiResponse) => void, skipCache = false) => {
  return queryStravaApi(`/strava/get/segment/${id}`, callback, skipCache);
};

export const getRoute = (id: string, callback: (segment: RouteApiResponse) => void, skipCache = false) => {
  return queryStravaApi(`/strava/get/route/${id}`, callback, skipCache);
};

export const updateUserSettings = (
  settings: Omit<HeadwindUserSettings, 'donated'>,
  callback: (user: UserApiResponse) => void,
) => {
  return queryStravaApi(`/strava/update/user-settings/${JSON.stringify(settings)}`, callback, true);
};

export const getShared = (
  type: string,
  uid: string,
  rid: string,
  callback: (shared: RideApiResponse | RouteApiResponse) => void,
  skipCache = false,
) => {
  return apiRequest(`/strava/share/${type}/${uid}/${rid}`, callback, skipCache);
};

export const getApproxUserCount = (callback: (users: number) => void) => {
  const { store } = useStore();
  const uid = store().stravaUserID;
  if (!uid) {
    showToast('Error fetching approx user count. No strava user id found.', 'error');
  } else {
    return queryStravaApi(
      `/strava/get/info/Users/${uid}`,
      (info: DynamoTableInfoResponse) => {
        let userNo = 0;
        if (info.success && info.data && info.data?.Table?.ItemCount) {
          userNo = info.data.Table.ItemCount;
        }
        callback(userNo);
      },
      true,
    );
  }
};

export const donate = (email: string, callback: (user: DonateApiResponse) => void) => {
  let endpoint = '/strava/donate';
  if (email !== '') {
    endpoint = `${endpoint}/${email}`;
  }
  return queryStravaApi(
    endpoint,
    user => {
      callback(user);
    },
    true,
  );
};
