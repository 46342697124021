import { ParentComponent, Show, createSignal, mergeProps, onMount } from 'solid-js';
import { donate, getPriceInLocalCurrency } from '../../api';
import { useStore } from '../../store';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { StripePaymentRequestButton } from '../StripePaymentRequestButton';
import styles from './DonateButton.module.scss';

interface Props {
  amount: number;
  donated?: number;
  showSpiel?: boolean;
  light?: boolean;
  class?: string;
  full?: boolean;
}

export const DonateButton: ParentComponent<Props> = passedProps => {
  const props = mergeProps({ donated: 0, showSpiel: true }, passedProps);
  const { updateStore } = useStore();
  const [successOpen, setSuccessOpen] = createSignal(false);
  const [ready, setReady] = createSignal(false);
  const [localPrice, setLocalPrice] = createSignal();

  onMount(async () => {
    const localPrice = await getPriceInLocalCurrency(props.amount);
    setLocalPrice(localPrice);
  });

  const onSuccess = (message: string, email: string) => {
    setSuccessOpen(true);
    donate(email, user => {
      if (user.success && user.data && user.data.headwind) {
        updateStore.settings(user.data.headwind);
      }
    });
  };

  const onSuccessClose = () => {
    setSuccessOpen(false);
  };

  const onReady = () => {
    setReady(true);
  };

  return (
    <div>
      <Show when={props.showSpiel}>
        <p class={styles.message}>
          {props.donated > 0 && (
            <>
              Thank you for donating <span class={styles.heart}>&hearts;</span>. You will now be among the first to
              receive new updates. You have currently donated {props.donated} {props.donated > 1 ? 'coffees' : 'coffee'}
              .
            </>
          )}
          {props.donated === 0 && (
            <>
              If you <span class={styles.heart}>&hearts;</span> headwind, then be the first to try out new features by
              donating the price of a coffee and helping to keep headwind.app free for everyone.
            </>
          )}
        </p>
      </Show>
      <StripePaymentRequestButton
        paymentModalTitle="Donate a coffee"
        amount={props.amount}
        icon="IconCoffee"
        full={props.full}
        appearance={props.light ? 'light' : 'outline'}
        onSuccess={onSuccess}
        onReady={onReady}
        class={props.class}
        loading={!localPrice()}
      >
        {props.donated > 0 && <>Donate another </>}
        {props.donated === 0 && <>Donate </>}
        <>{localPrice()}</>
      </StripePaymentRequestButton>
      {ready() && (
        <Modal
          open={successOpen()}
          onClose={onSuccessClose}
          image="/payment-successful.png"
          maxWidth={500}
          footer={
            <div class={styles.successFooter}>
              <Button onClick={onSuccessClose}>Done</Button>
            </div>
          }
        >
          <h2>Payment successful!</h2>
          <p>Thank you for helping to keep headwind free for all who want to use it.</p>
          <p>
            Honestly, your donation is much appreciated and the money will go towards developing bigger and better
            features.
          </p>
          <p>Enjoy!</p>
        </Modal>
      )}
    </div>
  );
};
