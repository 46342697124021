export const fragmentShader = `
  uniform float texWidth;
  uniform float texHeight;
  uniform sampler2D tDiffuse;

  uniform vec3 keyColor;
  uniform float similarity;
  uniform float smoothness;
  uniform float spill;

  varying vec2 vUv;

  // From https://github.com/libretro/glsl-shaders/blob/master/nnedi3/shaders/rgb-to-yuv.glsl
  vec2 RGBtoUV(vec3 rgb) {
    return vec2(
      rgb.r * -0.169 + rgb.g * -0.331 + rgb.b *  0.5    + 0.5,
      rgb.r *  0.5   + rgb.g * -0.419 + rgb.b * -0.081  + 0.5
    );
  }

  vec4 ProcessChromaKey(vec2 texCoord) {
    vec4 rgba = texture2D(tDiffuse, texCoord);
    float chromaDist = distance(RGBtoUV(texture2D(tDiffuse, texCoord).rgb), RGBtoUV(keyColor));

    float baseMask = chromaDist - similarity;
    float fullMask = pow(clamp(baseMask / smoothness, 0.0, 1.0), 1.5);
    rgba.a = fullMask;

    float spillVal = pow(clamp(baseMask / spill, 0.0, 1.0), 1.5);
    float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0.0, 1.0);
    rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);

    // Silly fix for safari where color white with opacity of 0
    // refuses to become transparent
    if (rgba.a == 0.0) {
      rgba.rgba = vec4(0.0, 0.0, 0.0, 0.0);
    }

    return rgba;
  }

  void main(void) {
    vec2 texCoord = vUv;
    gl_FragColor = ProcessChromaKey(texCoord);
  }
`;
