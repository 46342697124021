import { animate } from 'motion';
import { ParentComponent, createEffect, createSignal } from 'solid-js';
import headwindLogo from '../../assets/images/headwind-logo.svg';
import { Image } from '../Image';
import { themeClass } from '../ThemeProvider';
import styles from './Splash.module.scss';

interface Props {
  loaded: boolean;
  version: string;
}

export const Splash: ParentComponent<Props> = props => {
  const [loaded, setLoaded] = createSignal(props.loaded);
  let splashBackRef: HTMLDivElement | undefined = undefined;
  let contentRef: HTMLDivElement | undefined = undefined;

  createEffect(() => {
    const promises: Promise<true>[] = [];
    if (props.loaded !== loaded() && splashBackRef && contentRef) {
      if (props.loaded) {
        promises.push(animate(splashBackRef, { width: '500vw', height: '500vw' }, { duration: 1 }).finished);
        promises.push(animate(contentRef, { opacity: 0 }, { duration: 0.2 }).finished);
        Promise.all(promises).then(() => {
          setLoaded(true);
        });
      } else {
        setLoaded(false);
        animate(splashBackRef, { width: '0', height: '0' }, { duration: 0.5 });
        animate(contentRef, { opacity: 1 }, { duration: 0.2, delay: 0.4 });
      }
    }
    return props.loaded;
  }, props.loaded);

  return (
    <div
      class={themeClass(styles.dark, styles.splash, {
        [styles.loaded]: loaded(),
      })}
    >
      <div class={styles.splashContent} ref={contentRef}>
        <Image
          width={80}
          height={80}
          src={headwindLogo}
          transparentBg
          hideLoading
          alt="Headwind"
          lazyload={false}
          objectFit="contain"
        />
        <div class={styles.title}>Headwind</div>
        <div class={styles.version}>Version {props.version}</div>
      </div>
      <div class={styles.splashBack} ref={splashBackRef}></div>
    </div>
  );
};
