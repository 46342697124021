import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  Context,
  createContext,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
  ParentComponent,
  useContext,
} from 'solid-js';
import WebFont from 'webfontloader';
import headwindLogo from '../../assets/images/headwind-logo.svg';
import { Splash } from '../Splash';

interface Props {
  stripeKey: string;
  stripeInstance?: Stripe | null;
  fontReady?: boolean;
  logoReady?: boolean;
}

const defaultProps: Props = {
  stripeKey: 'pk_test_stripekeyhere',
  stripeInstance: null,
  fontReady: false,
  logoReady: false,
};

const AssetsContext: Context<Props> = createContext(defaultProps);

export const AssetsProvider: ParentComponent<Props> = passedProps => {
  const props = mergeProps(defaultProps, passedProps);
  const [assetsProps, setAssetsProps] = createSignal(props);
  const [assetsLoaded, setAssetsLoaded] = createSignal(false);
  let splashTimeout: number = 0;

  // TODO: handle if something does not load. Don't want to be stuck on splash
  onMount(async () => {
    // load stripe
    try {
      const stripe = await loadStripe(props.stripeKey);
      setAssetsProps(mergeProps(assetsProps(), { stripeInstance: stripe }));
    } catch (e: any) {
      throw new Error(e);
    }

    // load fonts
    // NOTE: fonts are loaded here and in index.html to stop font loading jitters
    WebFont.load({
      google: {
        families: ['Roboto:300,400,500', 'Raleway:300,400,600'],
      },
      active: () => {
        setAssetsProps(mergeProps(assetsProps(), { fontReady: true }));
      },
    });

    // Load up headwind logo
    const logo = new Image();
    logo.onload = () => {
      setAssetsProps(mergeProps(assetsProps(), { logoReady: true }));
    };
    logo.src = headwindLogo;
  });

  onCleanup(() => {
    clearTimeout(splashTimeout);
  });

  createEffect(() => {
    const assets = assetsProps();
    if (assets.fontReady && assets.logoReady && assets.stripeInstance) {
      splashTimeout = window.setTimeout(() => {
        setAssetsLoaded(true);
      }, 2000);
    }
  }, assetsProps());

  return (
    <>
      <Splash version={APP_VERSION} loaded={assetsLoaded()} />
      <AssetsContext.Provider value={assetsProps()}>{props.children}</AssetsContext.Provider>
    </>
  );
};

export const useAssets = () => {
  return useContext(AssetsContext);
};
